import React from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import {
  RamblerRecommendationBlock,
  RecommendationBlockItemType,
} from 'common/components/RamblerComponents/RamblerRecommendationBlock';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectRecommendBlockID } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { RCMclick, RCMshow } from 'utils/counters/atdRecommender';

import { useRecommendedNewsWidget } from '../TopNewsWidget/hooks/useRecommendedNewsWidget';

type GetItemsPropsType = {
  clusters: CardData[];
  blockId: string;
  contextItemId: RCM_BLOCK_TYPE;
};

/**
 * Функция получения массива item'ов из карточек.
 * @param props.clusters - массив карточек кластеров;
 * @param props.blockId - id рекомендательного блока;
 * @param props.contextItemId - идентификатор материала, на котором показывается блок рекомендаций.
 */
export const getItems = ({
  clusters,
  blockId,
  contextItemId,
}: GetItemsPropsType): RecommendationBlockItemType[] =>
  clusters.map(
    (cluster, index) =>
      ({
        /**
         * Данные ниже мы отбрасываем при адаптации.
         * Если они все таки понадобятся, их можно попытаться сохранить.
         */
        _id: '',
        feed_title: '',
        rcm_id: '',
        img_color: '',
        show_id: '',
        item_author: '',
        item_category: [],
        feed_id: blockId,
        item_id: cluster.id,
        item_title: cluster.title,
        item_description: cluster.description,
        item_image: cluster.image.url,
        item_pubdate: cluster.publicationTime,
        item_url: cluster.url,
        comments_count: cluster.commentsCount,
        onClick: () => {
          if (!cluster.item) return;

          RCMclick({
            blockId,
            item: cluster.item,
            position: index + 1,
            contextItemId,
          });
        },
        onShow: () => {
          if (!cluster.item) return;

          RCMshow({
            blockId,
            item: cluster.item,
            position: index + 1,
            contextItemId,
          });
        },
      }) as RecommendationBlockItemType,
  );

type RecommendationBlockPropsType = {
  clusterId: CardData['id'];
  level: number;
  rcmKey: RCM_BLOCK_TYPE;
  title?: string;
  withImage?: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  onClick?: () => void;
};

/**
 * Виджет рекоммендуемых новостей.
 * @param props.clusterId - id кластера;
 * @param props.title - заголовок виджета;
 * @param props.rcmKey - id блока рекомендаций;
 * @param props.withImage - флаг, что нужна картинка для первой новости;
 * @param props.onClick - функция клика по новости, используется для отправки в ЯМ;
 * @param props.containerRef - реф для контейнера виджета, чтобы получать высоту выше.
 */
export const RecommendationBlock = withErrorBoundary(
  function RecommendationBlock({
    clusterId,
    title = 'Рекомендуем',
    withImage = false,
    containerRef,
    rcmKey,
    onClick,
  }: RecommendationBlockPropsType) {
    const { clusters } = useRecommendedNewsWidget(clusterId);
    const blockId = useAppSelector(selectRecommendBlockID(rcmKey));

    const items = getItems({
      clusters,
      blockId,
      contextItemId: rcmKey,
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div ref={containerRef} onClick={onClick}>
        <RamblerRecommendationBlock
          title={title}
          projectType="media"
          items={items}
          withImage={withImage}
        />
      </div>
    );
  },
);
